import translations from './translations'

const config = {
	legacy: false,
	locale: 'nl',
	fallbackLocale: 'en',
	messages: {
		...translations,
	},
}

export default defineI18nConfig(() => config)
