export default {
	components: {
		app: {
			header: {
				LocaleSwitch: {
					welcome: 'Welkom',
				},
			},
		},
	},
	site: {
		meta: {
			title: 'Joke Vink',
			description: 'Ik ben een multidisciplinaire kunstenaar uit Nederland. Bekijk mijn werk!',
		},
	},
	pages: {
		home: {
			meta: {
				title: 'Joke Vink',
				description: 'Ik ben een multidisciplinaire kunstenaar uit Nederland. Bekijk mijn werk!',
			},
			title: 'Joke Vink',
			tagline: 'Kunstenaar',
		},
		about: {
			meta: {
				title: 'Over Joke Vink',
				description:
					'Mijn naam is Joke Vink, geboren en getogen in Bergen NH in Nederland en thans woonachtig in Alkmaar. Mijn atelier heb ik bij de Artfarm in Heerhugowaard.',
			},
			title: 'Over Mij',
			description: `
        Mijn naam is Joke Vink, geboren en getogen in Bergen NH in Nederland en thans woonachtig in Alkmaar. Mijn atelier heb ik bij de Artfarm in Heerhugowaard.
        Vanuit een diep besef dat alles in de natuur met elkaar in verbinding staat en een verandering ergens ook gevolgen heeft voor elders, gaat mijn werk, (schilderijen, collages en werk in gemengde technieken), altijd over verbondenheid. Van de natuur en soms door mensen én natuur, want mensen zijn onderdeel van de natuur.
        Men zegt dat mijn werk lyrisch of organisch abstract is, maar soms is het ook verstild of neigt het naar figuratie. Het beweegt zich op de grens van figuratie en abstract, met uitstapjes naar beide kanten. Ik werk vaak in series op een thema.
      `,
			slug: {
				about: 'over',
			},
		},
		contact: {
			meta: {
				title: 'Contact Joke Vink',
				description: 'Neem contact op met mij of kom langs bij Artfarm Heerhugowaard, Nederland',
			},
			title: 'Contact',
			tagline: 'Neem contact op',
			email: 'hallo',
			description: 'Artfarm Heerhugowaard',
		},
	},
}
