export default {
	components: {
		app: {
			header: {
				LocaleSwitch: {
					welcome: 'Welcome',
				},
			},
		},
	},
	site: {
		meta: {
			title: 'Joke Vink',
			description: 'I am a multidisciplinary artist based in the Netherlands. Check out my work!',
		},
	},
	pages: {
		home: {
			meta: {
				title: 'Joke Vink',
				description: 'I am a multidisciplinary artist based in the Netherlands. Check out my work!',
			},
			title: 'Joke Vink',
			tagline: 'Artist',
		},
		about: {
			meta: {
				title: 'About Joke Vink',
				description:
					'My name is Joke Vink, born and raised in Bergen NH in the Netherlands and currently living in Alkmaar. My studio is located at the Artfarm in Heerhugowaard.',
			},
			title: 'About Me',
			description: `
        My name is Joke Vink, born and raised in Bergen NH in the Netherlands and currently living in Alkmaar. My studio is located at the Artfarm in Heerhugowaard.
        From a deep understanding that everything in nature is connected to each other and that a change somewhere has consequences for elsewhere, my work, (paintings, collages and mixed media), always revolves around connectedness. From nature and sometimes by people and nature, because people are part of nature.
        People say that my work is lyrical or organic abstract, but sometimes it is also languid or tends towards figuration. It moves at the border of figuration and abstraction, with excursions to both sides. I often work in series on a theme.
      `,
			slug: {
				about: 'about',
			},
		},
		contact: {
			meta: {
				title: 'Contact Joke Vink',
				description: 'Contact me or look me up at Artfarm Heerhugowaard, The Netherlands',
			},
			title: 'Contact',
			tagline: 'Contact me',
			email: 'hello',
			description: 'Artfarm Heerhugowaard, The Netherlands',
		},
	},
}
